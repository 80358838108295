<template>
  <main class="bg-gray-200">
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <!-- prettier-ignore -->
        <nav class="breadcrumb" aria-label="breadcrumbs" v-if="company">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }" ><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{ name: 'companies' }" ><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link tag="li" :to="{ name: 'accounts-org', params: { cid, oid } }" ><a>{{ $t('breadcrumb_accounts') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <sqr-progress v-if="loading" />
    <sqr-error :error="cLoadError" />
    <sqr-error :error="loadError" />
    <section class="px-2 md:px-0 py-4" v-if="isManager">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-12-tablet is-12-desktop">
            <div class="field is-clearfix">
              <div class="buttons is-right">
                <sqr-router-link
                  label="accounts_org"
                  :to="{ name: 'org', params: { cid, oid } }"
                  color="white"
                  size="medium"
                />
                <sqr-router-link
                  label="accounts_org_all"
                  :to="{ name: 'accounts', params: { cid } }"
                  color="white"
                  size="medium"
                />
                <sqr-router-link
                  icon="user-plus"
                  label="accounts_add"
                  :to="{ name: 'account-add', params: { cid } }"
                  color="primary"
                  size="medium"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="columns is-centered is-multiline">
          <div
            class="column is-12-mobile is-4-tablet is-4-desktop"
            v-for="(accounts, teamId) in groups"
            :key="teamId"
          >
            <h1 class="title">
              {{ teamsById[teamId] && teamsById[teamId].name }}
            </h1>
            <account-card
              v-for="account in accounts"
              :key="account.id"
              :account="account"
            />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style lang="postcss" scoped>
.links a {
  @apply block p-2 mr-4;
}
.links a:hover {
  @apply bg-blue-100;
}
</style>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { fromPairs, groupBy } from 'ramda';
import company from './company';
import SqrError from '@/sqrd/SqrError';
import SqrProgress from '@/sqrd/SqrProgress';
import SqrRouterLink from '@/sqrd/SqrRouterLink';
import CompanyName from '@/components/CompanyName';
import AccountCard from '@/components/AccountCard';

export default {
  name: 'AccountsOrg',
  mixins: [company],
  props: {
    oid: String,
  },
  components: {
    SqrError,
    SqrProgress,
    SqrRouterLink,
    CompanyName,
    AccountCard,
  },
  computed: {
    ...mapGetters('perms', ['isManager']),
    ...mapState('org', { org: 'record' }),
    ...mapState('org', ['loading', 'loadError']),
    teamsById() {
      const content = this.org?.content ?? [];
      const pairs = content.filter(i => i.type === 'team').map(i => [i.id, i]);
      return fromPairs(pairs);
    },
    groups() {
      const content = this.org?.content ?? [];
      const companyId = this.cid;
      const accounts = content
        .filter(i => i.type === 'account')
        .map(account => ({
          ...account,
          companyId,
        }));
      return groupBy(i => i.parentId, accounts);
    },
  },
  mounted() {
    this.sub({
      path: ['companies', this.cid, 'orgs'],
      id: this.oid,
    });
  },
  methods: {
    ...mapActions('org', ['sub']),
  },
};
</script>
