<template>
  <div
    class="bg-white rounded p-4 pb-0 my-8 border shadow hover:shadow-xl"
    v-if="account"
  >
    <div
      class="-mt-4 -ml-4 pl-4 w-32 uppercase text-sm text-left font-medium bg-gray-200 border-r border-b"
    >
      <span class="mr-1"><fa :icon="['fal', 'user']" /></span>
      {{ $t('account') }}
    </div>
    <div class="flex">
      <div class="flex-auto my-2 text-2xl">
        <router-link
          :to="{
            name: 'account',
            params: { cid: account.companyId, aid: account.id },
          }"
        >
          <account-name :account="account" />
        </router-link>
        <div class="tags">
          <span class="tag is-danger is-light" v-show="isManager">{{
            $t('account_roles_manager')
          }}</span>
          <span class="tag is-light" v-show="isEmployee">{{
            $t('account_roles_user')
          }}</span>
          <span class="tag is-light" v-show="isRW">{{
            $t('account_accesses_rw')
          }}</span>
          <span class="tag is-light" v-show="isRO">{{
            $t('account_accesses_ro')
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { contains } from 'ramda';
import AccountName from '@/components/AccountName';
export default {
  name: 'AccountCard',
  props: {
    account: Object,
  },
  components: { AccountName },
  computed: {
    ...mapState('company', { company: 'record' }),
    isEnabled() {
      return contains(this.account?.id, this.company?.enabledUids ?? []);
    },
    isManager() {
      return (
        this.isEnabled &&
        contains(this.account?.id, this.company?.managerUids ?? [])
      );
    },
    isEmployee() {
      return (
        this.isEnabled &&
        contains(this.account?.id, this.company?.employeeUids ?? [])
      );
    },
    isRW() {
      return (
        this.isEnabled && contains(this.account?.id, this.company?.rwUids ?? [])
      );
    },
    isRO() {
      return (
        this.isEnabled &&
        !contains(this.account?.id, this.company?.rwUids ?? [])
      );
    },
  },
};
</script>
