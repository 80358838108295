<template>
  <main>
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <!-- prettier-ignore -->
        <nav class="breadcrumb" aria-label="breadcrumbs" v-if="company">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }" ><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{ name: 'companies' }" ><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link tag="li" :to="{ name: 'accounts-org', params: { cid, oid: 'default' } }" ><a>{{ $t('breadcrumb_accounts') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'account-add', params: { cid } }" ><a>{{ $t('breadcrumb_account_add') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <section class="px-2 md:px-0 py-4 md:py-8 lg:py-16 xl:py-32">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-9-tablet is-6-desktop">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <sqr-input-text
                    label="account_add_given_name"
                    v-model="account.givenName"
                    :valid="!$v.account.givenName.$invalid"
                    :invalid="$v.account.givenName.$error"
                    size="large"
                    @keyup.enter="createAndGo()"
                  />
                  <div
                    class="help is-size-5 is-danger"
                    v-if="$v.account.givenName.$error"
                  >
                    {{ $t('validation_required') }}
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <sqr-input-text
                    label="account_add_family_name"
                    v-model="account.familyName"
                    size="large"
                    :valid="!$v.account.familyName.$invalid"
                    :invalid="$v.account.familyName.$error"
                    @keyup.enter="createAndGo()"
                  />
                  <div
                    class="help is-size-5 is-danger"
                    v-if="$v.account.familyName.$error"
                  >
                    {{ $t('validation_required') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <sqr-input-email
                label="account_add_email"
                v-model="account.email"
                size="large"
                :valid="!$v.account.email.$invalid"
                :invalid="$v.account.email.$error"
                @keyup.enter="createAndGo()"
              />
              <div
                class="help is-size-5 is-danger"
                v-if="$v.account.email.$error"
              >
                {{ $t('validation_email') }}
              </div>
              <div class="help is-size-5 py-4">
                {{ $t('account_add_email_help') }}
                <a class="text-gray-200" @click="password = !password">&pi;</a>
              </div>
            </div>
            <div class="field" v-if="password">
              <sqr-input-text
                label="account_add_uid"
                v-model="account.uid"
                size="large"
                @keyup.enter="createAndGo()"
              />
            </div>
            <div class="field" v-if="password">
              <sqr-input-password
                label="account_add_password"
                v-model="account.password"
                size="large"
                @keyup.enter="createAndGo()"
              />
            </div>
            <sqr-error-note :error="addError" />
            <div class="buttons is-right">
              <sqr-button
                label="buttons_cancel"
                @click="$router.back()"
                size="large"
                color="white"
              />
              <sqr-button
                icon="user-plus"
                label="account_add_confirm"
                @click="createAndGo()"
                color="primary"
                size="large"
                :is-loading="adding"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import { mapActions, mapState } from 'vuex'
import SqrInputEmail from '@/sqrd/SqrInputEmail';
import SqrInputText from '@/sqrd/SqrInputText';
import SqrInputPassword from '@/sqrd/SqrInputPassword';
import SqrErrorNote from '@/sqrd/SqrErrorNote';
import SqrButton from '@/sqrd/SqrButton';
import company from './company';
// import SqrRouterLink from '@/sqrd/SqrRouterLink'
// import AccountCard from '@/components/AccountCard'
import CompanyName from '@/components/CompanyName';

import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'AccountAdd',
  mixins: [validationMixin, company],
  components: {
    SqrInputText,
    SqrInputEmail,
    SqrInputPassword,
    SqrErrorNote,
    SqrButton,
    CompanyName,
  },
  data() {
    return {
      account: {
        uid: '',
        givenName: '',
        familyName: '',
        email: '',
        password: '',
      },
      adding: false,
      addError: null,
      password: false,
    };
  },
  validations: {
    account: {
      givenName: { required },
      familyName: { required },
      email: { required, email },
    },
  },
  mounted() {},
  methods: {
    async createAndGo() {
      try {
        if (this.$v.$invalid) {
          this.$v.$touch();
          return;
        }
        this.adding = true;
        this.addError = null;
        const payload = { ...this.account, cid: this.cid };
        const res = await this.$f('invite')(payload);
        const { companyId, accountId } = res.data;
        this.$router.push({
          name: 'account',
          params: { cid: companyId, aid: accountId },
        });
      } catch (error) {
        this.addError = error;
        return Promise.reject(error);
      } finally {
        this.adding = false;
      }
    },
  },
};
</script>
