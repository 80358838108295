<template>
  <main>
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <!-- prettier-ignore -->
        <nav class="breadcrumb" aria-label="breadcrumbs" v-if="company">
          <ul>
            <router-link tag="li" :to="{name: 'home'}"><a>{{$t('breadcrumb_home')}}</a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{name: 'companies'}"><a>{{$t('breadcrumb_companies')}}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link tag="li" :to="{name: 'accounts', params: { cid }}"><a>{{$t('breadcrumb_accounts')}}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <sqr-progress v-if="loading" />
    <sqr-error :error="cLoadError" />
    <sqr-error :error="loadError" />
    <section class="px-2 md:px-0 py-4" v-if="isManager">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-10-tablet is-7-desktop">
            <div class="field is-clearfix">
              <div class="buttons is-pulled-right">
                <sqr-router-link
                  label="accounts_org_view"
                  :to="{
                    name: 'accounts-org',
                    params: { cid, oid: 'default' },
                  }"
                  color="white"
                  size="medium"
                />
                <sqr-router-link
                  icon="user-plus"
                  label="accounts_add"
                  :to="{ name: 'account-add', params: { cid } }"
                  color="primary"
                  size="medium"
                />
              </div>
            </div>
            <account-card
              v-for="account in accounts"
              :key="account.id"
              :account="account"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="px-4 md:px-0 pt-2 border-t bg-white sticky bottom-0">
      <div class="container">
        <sqr-fire-page
          v-bind="pagination"
          @page-set="pageSet"
          @page-size="pageSize"
        />
      </div>
    </section>
  </main>
</template>

<style lang="postcss" scoped>
.links a {
  @apply block p-2 mr-4;
}
.links a:hover {
  @apply bg-blue-100;
}
</style>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import company from './company';

import SqrError from '@/sqrd/SqrError';
import SqrProgress from '@/sqrd/SqrProgress';
import SqrRouterLink from '@/sqrd/SqrRouterLink';
import SqrFirePage from '@/sqrd/SqrFirePage';

import CompanyName from '@/components/CompanyName';
import AccountCard from '@/components/AccountCard';

export default {
  name: 'Accounts',
  mixins: [company],
  components: {
    SqrError,
    SqrProgress,
    SqrRouterLink,
    SqrFirePage,
    CompanyName,
    AccountCard,
  },
  computed: {
    ...mapGetters('perms', ['isManager']),
    ...mapState('accounts', {
      accounts: 'records',
    }),
    ...mapState('accounts', ['loading', 'loadError']),
    ...mapGetters('accounts', ['pagination']),
  },
  mounted() {
    this.sub({
      path: ['companies', this.cid, 'accounts'],
      // where: [['archived', '==', false]],
      orderBy: [['nameSort', 'asc']],
    });
  },
  methods: {
    ...mapActions('accounts', ['sub', 'pageSet', 'pageSize']),
  },
};
</script>
