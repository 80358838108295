<template>
  <a
    :class="buttonClass"
    :data-tooltip="tooltipTr"
    @click="click"
    :disabled="disabled"
    :style="buttonStyle"
  >
    <span v-if="icon" class="icon"><fa :icon="[iconPrefix, icon]" /></span>
    <span v-if="labelTr">{{ labelTr }}</span>
  </a>
</template>

<script>
import label from './mixins/label';
import size from './mixins/size';
import color from './mixins/color';
import tooltip from './mixins/tooltip';

export default {
  name: 'sqr-button',
  mixins: [label, tooltip, color, size],
  props: {
    icon: String,
    iconPrefix: { type: String, default: 'fal' },
    isOutlined: Boolean,
    isLoading: Boolean,
    dropdownItem: Boolean,
    disabled: Boolean,
  },
  computed: {
    buttonClass() {
      return Object.assign(
        {
          button: !this.dropdownItem,
          'dropdown-item': this.dropdownItem,
          'is-outlined': this.isOutlined,
          'is-loading': this.isLoading,
          tooltip: this.tooltipTr,
        },
        this.colorClass,
        this.sizeClass
      );
    },
    buttonStyle() {
      if (this.isFullwidth) {
        return 'justify-content: left';
      } else {
        return '';
      }
    },
  },
  methods: {
    click(event) {
      if (this.disabled) return;
      this.$emit('click', event);
    },
  },
};
</script>
